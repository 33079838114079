@media (max-width: 1023px) {
  .cardbudget-time {
    margin-top: 15px!important;
    margin-left: 0px;
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  .cardbudget-div {
    display: flex;
  }

  .priority-div {
    display: flex;
    margin-bottom: 15px;
  }

  .cardbudget-time {
    margin-top: 0px;
    margin-left: 15px;
  }
}

@media (min-width: 1280px) {
  .cardbudget-div {
    display: block;
    position: absolute;
    right: -120px;
    top: 5px;
  }

  .priority-div {
    display: block;
    position: absolute;
    left: -25%;
    top: 5px;
    margin-bottom: 0px;
    max-width: 250px;
    font-size: 0.8em;
  }

  .cardbudget-time {
    margin-top: 15px!important;
    margin-left: 0px;
  }
}

.cardbudget {
  width: 379.73px!important;
}

.css-ja5taz-MuiTooltip-tooltip {
  max-width: max-content!important;
}

.ql-editor {
  min-height: 200px;
}